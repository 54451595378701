import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, useLocation, Redirect } from '@reach/router'

import { Alert, Toast, Dialog } from '@refera/ui-web'
import {
  loginUser,
  postAccessHistory,
  disableApplication,
  logout,
  setUserProfile,
} from '_modules/authentication/actions'
import Textfield from '_components/textfield'
import PasswordField from '_components/password-field'
import Svg from '_components/svg'
import ReferaLogo from '_assets/svgs/refera-logo-negative.svg'
import useForm from '_hooks/use-form'
import { validationEmail } from '_utils/helpers'
import { loginErrorSelector } from '_modules/authentication/selectors'
import Button from '_components/button'
import { ROUTES, WIDGETS_ROUTE } from '_utils/constants'
import { HEADER_TAGS } from '_utils/header-tags'
import { useLocalStorage } from '_hooks/use-local-storage'
import {
  Danger as DangerIcon,
  House2,
  SecurityUser,
  DollarCircle,
  House,
  Buildings2,
  Driver,
  TagUser,
  User,
  Brush2,
} from '@refera/ui-icons'

import * as Modal from '_components/modal/generic-modal'

import useStyles from './styles'
import axios from 'axios'
import { API_URL } from '_/config/environment'
import { useToast } from '_/hooks/use-toast'
import { USER_ROLES } from '_/utils/user'

const INITIAL_STATE = {
  email: '',
  password: '',
}

const Login = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const [isLoadingRight, setLoadingRight] = useState(false)
  const error = useSelector(loginErrorSelector)
  const [toastOpen, setToastOpen] = useState(false)
  const [disablePassword, setDisablePassword] = useState(false)
  const [isAplicationOff, setIsAplicationOff] = useLocalStorage('isApplicationOff', null)
  const { dialogMessage, showMessage } = location.state || {}
  const [isOpen, setModalOpen] = useState(false)
  const [profileList, setProfileList] = useState([])
  const [key, setKey] = useState(null)
  const { showToast } = useToast()

  const userProfilesImage = {
    refera: <SecurityUser className={styles.iconProfile} />,
    intermediary: <Buildings2 className={styles.iconProfile} />,
    saas: <Driver className={styles.iconProfile} />,
    tradesman: <Brush2 className={styles.iconUser} />,
    owner: <House2 className={styles.iconProfile} />,
    requester: <TagUser className={styles.iconProfile} />,
    payer: <DollarCircle className={styles.iconProfile} />,
    lessee: <House className={styles.iconProfile} />,
  }

  const redirectToTemporaryPassword = useCallback(
    async params => {
      try {
        const { data } = await axios.post(
          `${API_URL}/temporary-password-reset/`,
          { email: params.email },
          {
            headers: { Authorization: `Token ${params.key}` },
          }
        )

        navigate(data.reset_url, { state: { redirected: true } })
      } catch (e) {
        console.error(e)
      }
    },
    [error]
  )

  useEffect(() => {
    if (error?.get('isTemporaryPassword')) {
      redirectToTemporaryPassword({ key: error?.get('key'), email: error.toJS().user.email })
    }
  }, [error])

  useEffect(() => {
    dispatch(disableApplication()).then(res => {
      const applicationOffValue = res?.results?.reduce((acc, obj) => {
        if (obj.name === 'web_backoffice') {
          return obj.applicationOff
        }
        return acc
      }, null)

      setIsAplicationOff(applicationOffValue)
    })
  }, [])

  useEffect(() => {
    if (dialogMessage && showMessage) {
      setModalOpen(true)
    }
  }, [dialogMessage, showMessage])

  const handleDialogClose = () => {
    setModalOpen(false)
    navigate(location.pathname, { state: {}, replace: true })
  }

  const consecutiveFails = useMemo(() => {
    setToastOpen(!!error?.get('information'))
    return error?.get('information') || null
  }, [error])

  useEffect(() => {
    if (!disablePassword && consecutiveFails) {
      setDisablePassword(true)
      setTimeout(() => {
        setDisablePassword(false)
      }, 60000)
    }
  }, [consecutiveFails])

  const validate = useCallback(values => {
    const errors = {}

    if (!validationEmail(values.email)) {
      errors.email = 'Email inválido'
    }
    if (!values.password?.length) {
      errors.password = 'Campo Obrigatório'
    }

    return errors
  }, [])

  const { errors, values, handleInputChange, touched, handleBlur, isValid, setTouchedAll } =
    useForm(INITIAL_STATE, validate)

  const handleSubmit = useCallback(
    async event => {
      setLoadingRight(true)
      setTimeout(1000) // Não remover, evita que o usuário clique inúmeras vezes seguidas no botão de login causando possíveis instabilidades
      try {
        event.preventDefault()
        setTouchedAll()
        if (isValid) {
          await dispatch(loginUser(values))
            .then(async response => {
              if (response?.profiles?.length === 0) {
                return showToast({
                  type: 'error',
                  message: 'Nenhum perfil encontrado!',
                })
              }

              if (response?.profiles?.length > 1) {
                setKey(response?.key)
                return setProfileList(response.profiles)
              }

              await dispatch(
                setUserProfile(response?.key, { profile: response?.profiles?.[0]?.name })
              )
                .then(async () => {
                  await dispatch(postAccessHistory()).then(() => {
                    return handleRedirect(response?.profiles?.[0]?.name)
                  })
                })
                .catch(() => {
                  return showToast({
                    type: 'error',
                    message: 'Erro ao trazer as informações do perfil!',
                  })
                })
              return null
            })
            .catch(err => {
              if (err?.is_temporary_password) {
                return redirectToTemporaryPassword({
                  key: err?.key,
                  email: err?.user.email,
                })
              }

              if (err?.information) {
                return showToast({
                  type: 'error',
                  message: err?.information,
                })
              }
              return showToast({
                type: 'error',
                message: 'Erro ao trazer as informações do perfil!',
              })
            })
        }
      } finally {
        setLoadingRight(false)
      }
    },

    [dispatch, isValid, setTouchedAll, values, showToast]
  )

  const handleSelectProfile = useCallback(
    async name => {
      return dispatch(setUserProfile(key, { profile: name }))
        .then(async () => {
          return dispatch(postAccessHistory()).then(() => {
            return handleRedirect(name)
          })
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Erro ao trazer as informações do perfil!',
          })
        })
    },
    [dispatch, key]
  )

  const handleRedirect = useCallback(activeProfile => {
    const targetUrl = localStorage.getItem('target-url')
    if (targetUrl !== null && targetUrl !== '' && !targetUrl.includes('/login')) {
      localStorage.removeItem('target-url')
      return navigate(targetUrl)
    }

    if (USER_ROLES.TRADESMAN === activeProfile) {
      return navigate(ROUTES.TRADESMAN)
    }
    return navigate(WIDGETS_ROUTE.WIDGETS)
  }, [])

  const handleForgetPassword = useCallback(() => {
    // event => {
    // event.preventDefault()
    // TODO: handle forget Password
    navigate(ROUTES.PASSWORD_RECOVER)
  }, [])

  const handleLoggedOut = useCallback(() => {
    dispatch(logout())
    setProfileList([])
  }, [])

  if (isAplicationOff) {
    const systemPrevisionDate = new Date(isAplicationOff)
    return <Redirect noThrow to="/system-maintenance" state={{ systemPrevisionDate }} replace />
  }

  return (
    <>
      {HEADER_TAGS[ROUTES.MANAGE_ORDERS].header}
      <Grid className={styles.container}>
        <Grid container className={styles.content}>
          <Svg icon={ReferaLogo} ariaLabel="Refera logo" className={styles.icon} />
          <Typography component="h1" variant="h1" className={styles.title}>
            Faça seu login
          </Typography>
          <Toast open={toastOpen} autoHideDuration={6000} onClose={() => setToastOpen(false)}>
            <Alert severity="error" title={consecutiveFails} onClose={() => setToastOpen(false)} />
          </Toast>
          <Grid component="form" className={styles.form} onSubmit={handleSubmit}>
            <Textfield
              label="Login"
              className={styles.textfield}
              name="email"
              onChange={handleInputChange}
              value={values?.email}
              error={!error?.isEmpty() || (touched?.email && !!errors?.email)}
              helperText={(touched?.email && errors?.email) || ''}
              onBlur={handleBlur}
            />
            <PasswordField
              label="Senha"
              className={styles.textfield}
              fullWidth
              name="password"
              onChange={handleInputChange}
              value={values.password}
              error={
                (!error?.isEmpty() && !consecutiveFails) ||
                (touched?.password && !!errors?.password && !consecutiveFails)
              }
              helperText={(touched.password && errors.password) || ''}
              onBlur={handleBlur}
              disabled={disablePassword}
            />
            {error?.size > 0 && (
              <Typography className={styles.error}>Credenciais inválidas</Typography>
            )}
            <Grid container justifyContent="space-between" className={styles.actions}>
              <Button
                className={styles.loginButton}
                type="submit"
                disabled={isLoadingRight}
                isLoading={isLoadingRight}
              >
                Entrar
              </Button>
              <Button
                className={styles.forgetButton}
                onClick={handleForgetPassword}
                color="primary"
              >
                Esqueci minha senha
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isOpen && (
        <Dialog
          open={isOpen}
          icon={DangerIcon}
          type="info"
          subject={dialogMessage}
          onApprove={handleDialogClose}
        />
      )}

      <Modal.Root className={styles.root} open={profileList?.length > 0} onClose={handleLoggedOut}>
        <Modal.TitleModal>
          <h1 className={styles.titleModal}>Definição do perfil de acesso</h1>
        </Modal.TitleModal>
        <Modal.Content className={styles.contentModal}>
          <span className={styles.textModal}>
            Defina abaixo com qual peril você quer acessar a plataforma:
          </span>
          <div className={styles.cardBox}>
            {profileList?.map(profile => (
              <button
                type="button"
                key={profile?.id}
                className={styles.card}
                onClick={() => handleSelectProfile(profile?.name)}
              >
                {userProfilesImage?.[profile?.name] ?? <User className={styles.iconProfile} />}
                {profile?.desc}
              </button>
            ))}
          </div>
        </Modal.Content>

        <Modal.Actions className={styles.actionConfirm}>
          <Modal.ButtonRed onClick={handleLoggedOut}>Fechar</Modal.ButtonRed>
        </Modal.Actions>
      </Modal.Root>
    </>
  )
}

export default Login
